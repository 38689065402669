export const useDictionaries = () => {

  const fuelDictionary = {
    "benzyna": "benzyna",
    "diesel": "diesel",
    "Benzyna+LPG": "benzyna/gaz",
    "diesel | elektryczny": "hybryda",
    "benzyna i elektryczny": "hybryda",
    "elektryczny": "elektryczny",
  }
  const gearboxDictionary = {
    "manualna skrzynia biegów": "manualna",
    "automatyczna skrzynia biegów": "automatyczna",
    "bezstopniowa skrzynia biegów": "automatyczna",
    "naped bezposredni, bez skrzyni biegów": "automatyczna",
    "automatyczna sekwencyjna": "automatyczna",
    "zautomatyzowana manualna skrzynia biegów": "automatyczna",
  }
  const driveDictionary = {
    "napęd przedni": "napęd przedni",
    "napęd tylny": "napęd tylny",
    "na wszystkie koła": "na wszystkie koła",
    "dołączany napęd na 4 koła": "na wszystkie koła",

  }

  const translateCarsData = (cars) => {
    cars.forEach((car, idx) => {
      cars[idx] = translateCarData(car)
    })
    return cars;
  }

  const translateCarData = (car) => {

    if (car.fuelType) {
      car.fuelType = fuelDictionary[car.fuelType.toLowerCase()].upperCaseFirst();
    }

    if (car.gearboxType) {
      car.gearboxType = gearboxDictionary[car.gearboxType.toLowerCase()].upperCaseFirst();
    }

    if (car.fuelTypes) {
      car.fuelTypes.map(f => fuelDictionary[f]);
    }

    if (car.gearboxTypes) {
      car.gearboxTypes.map(g => gearboxDictionary[g]);
    }

    return car;
  }

  const getFiltersDictionary = () => {

    console.log('🔴',Object.values(fuelDictionary).unique().toNameValueArrayOfObjects())

    return {
      gearbox: Object.values(gearboxDictionary).unique().toNameValueArrayOfObjects(true),
      drive: Object.values(driveDictionary).unique().toNameValueArrayOfObjects(true),
      fuel: Object.values(fuelDictionary).unique().toNameValueArrayOfObjects(true),
    }
  }

  const revTranslateFuel = (fuel) => Object.getKeysByValue(fuelDictionary, fuel);
  const revTranslateGearbox = (fuel) => Object.getKeysByValue(gearboxDictionary, fuel);

  return {
    translateCarData,
    translateCarsData,
    revTranslateGearbox,
    revTranslateFuel,
    getFiltersDictionary
  }
}
